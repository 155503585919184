// 
// flatpickr.scss
// Vendor style override and new style added

// flatpickr calendar

.flatpickr-calendar{
  background: var(--bs-mode);
  border: $input-border-width solid $input-focus-border-color !important;
  box-shadow: $box-shadow;
  margin-top: -4px;
  border-radius: $border-radius;
  border-radius: 0;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
.flatpickr-calendar:before, .flatpickr-calendar:after{
  display: none !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background: var(--bs-mode);
  border-color: $border-color;
}
.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  border-top-color: var(--#{$prefix}body-bg);
}
.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after, .flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--#{$prefix}body-bg);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: $secondary;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: $secondary;
}

.flatpickr-calendar.arrowBottom{
  margin-top: 1px;
}

// Flatpickr months
.flatpickr-months{
  position: relative;
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month{
  border-radius: $border-radius;
  margin: 3px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  transition: $transition-base;
  svg{
    height: 12px;
    width: 12px;
  }
  &:hover{
    background: rgba(var(--#{$prefix}primary-rgb), 0.1);
    svg{
      fill: var(--#{$prefix}primary);
    }
  }
}

// flatpickr day
.flatpickr-day, span.flatpickr-weekday, .flatpickr-months .flatpickr-month {
  color: var(--#{$prefix}gray-400);
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--#{$prefix}gray-300);
}
.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  fill: var(--#{$prefix}gray-400);
}
.flatpickr-day.today, .flatpickr-day.today:hover, .flatpickr-day.today:focus{
  background: rgba(var(--#{$prefix}primary-rgb), 0.1);
  color: var(--#{$prefix}primary);
  border-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay{
  background: var(--#{$prefix}primary);
  color: $white;
  border-color: var(--#{$prefix}primary);
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus{
  background: var(--#{$prefix}light);
  border-color: var(--#{$prefix}light);
  color: var(--#{$prefix}gray-400);
}
.flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange{
  background: rgba(var(--#{$prefix}primary-rgb), 0.1);
  color: var(--#{$prefix}primary);
  border-color: rgba(var(--#{$prefix}primary-rgb), 0.1);
}
.flatpickr-day.inRange {
  box-shadow: -5px 0 0 var(--#{$prefix}light), 5px 0 0 var(--#{$prefix}light);
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)){
  box-shadow: -10px 0 0 var(--#{$prefix}primary);
}
.flatpickr-input{
  &:disabled,
  &[readonly]{
    background-color: $input-bg;
  }
}

// Flatpickr Time
.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: var(--#{$prefix}light);
}
.flatpickr-calendar.hasTime .flatpickr-time {
  border-top-color: $border-color;
}
.flatpickr-current-month {
  font-size: 120%;
  padding: 9.48px 0 0 0;
  border-radius: $border-radius;
}
.flatpickr-time {
  input {
  color: var(--#{$prefix}gray-400);
  }
  .flatpickr-am-pm{
    color: var(--#{$prefix}gray-400);
  }
}

// Responsive

// Media sm down
@include media-breakpoint-down(sm){
  .flatpickr-calendar.hasTime {
    width: 60%;
    min-width: 60%;
    max-width: 60%;
  }
}

.form-control-lg.flatpickr.active{
  border-radius: $input-border-radius-lg $input-border-radius-lg 0 0 !important;
}

.flatpickr.active{
  border-color:$input-focus-border-color !important;
}

// Font size for form
.form-fs-lg {
  .flatpickr{
    font-weight: $font-weight-normal;
    font-size: $form-fs-lg;
  }
}

.form-fs-md {
  .flatpickr{
    font-weight: $font-weight-normal;
    font-size: $form-fs-md;
  }
}

.form-fs-sm {
  .flatpickr{
    font-weight: $font-weight-normal;
    font-size: $form-fs-sm;
  }
}

// Form border
.form-control-border{
  .flatpickr{
    margin-top: -1.2rem;
    padding-top: 1rem !important;
    padding-bottom: 0.8rem !important;
    color: $headings-color !important;
  }
} 

// Form background light
.form-control-bg-light{
  .flatpickr{
    border: $input-border-width solid transparent;
    background: var(--#{$prefix}light) !important;
    color: $headings-color;
  }
}

// Form border bottom
.form-border-bottom{
  border-bottom: $input-border-width $border-style $input-border-color;

  .flatpickr {
    border: none;
  }
}

.flatpickr-input.active{
  border-radius: $border-radius $border-radius 0 0 !important;
}