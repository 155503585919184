// 
// nouislider.scss
// Vendor style override and new style added

.noUi-connect {
  background:var(--#{$prefix}primary);
}
.noUi-target {
  background: rgba(var(--#{$prefix}primary-rgb), 0.1);
  border-radius: 4px;
  border: none;
  box-shadow: none;
}
.noUi-horizontal {
  height: 5px;
  .noUi-handle {
    width: 20px;
    height: 20px;
    right: -17px;
    top: -7px;
    cursor: e-resize;
    border-radius: 100%;
    border: 5px solid $white;
    background: var(--#{$prefix}primary);
    box-shadow: 0px 0px 0px 1px rgb(var(--#{$prefix}primary-rgb));

    &:before, &:after {
      display: none;
    }
  }
} 
.noui-wrapper input[class*='input-with-'] {
  background-color: transparent;
  border: none;
  color: var(--#{$prefix}primary);
  width: 70px;
}

// Input style for price range width
.noui-wrapper input[class*='input-with-']{
  width: 100px;
}

.input-with-range-max{
  text-align: right;
}